import * as React from "react";
import "./CTA.sass";
import { Link } from "gatsby";

const CTA = () => {
  return (
    <section id="cta">
      <div id="overlap" className="pattern-triangles-lg">
        <div id="holder">
          <div id="text">
            <div>
              <h2>
                Prêt à booster votre <span>commerce ?</span>
              </h2>
              <p>
                Vous avez le choix entre : suivre les étapes et constituer votre
                roue en toute autonomie ou parler avec un membre de notre
                équipe.
              </p>
            </div>
            <Link className="button primary" to="/price">
              Commencer
            </Link>
          </div>
          <div id="illustration"></div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
