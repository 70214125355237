import * as React from "react";
import "./Perks.sass";
import Perk from "./Perk";
import bouche from "../images/bouche.png";
import online from "../images/online.png";
import db from "../images/db.png";
import feedback from "../images/feedback.png";

// markup
const Perks = () => {
  return (
    <section id="perks" className="pattern-vertical-lines-lg">
      <div className="perk-row">
        <Perk
          subtitle="Chiffre d’affaires"
          title="Faites revenir vos clients et activez le bouche-à-oreille"
          text="Un client remportant un prix dans votre commerce reviendra plus souvent et sera plus à même de partager son expérience avec d’autres."
        ></Perk>
        <img src={bouche} alt="first perk"></img>
      </div>
      <div className="perk-row reversed">
        <img src={online} alt="second perk"></img>
        <Perk
          subtitle="Réseaux sociaux"
          title="Boostez votre présence 
          en ligne"
          text="Augmentez votre notoriété sur internet et les réseaux sociaux (Instagram, Facebook, Google Business) afin d’attirer plus de clients et ainsi augmenter vos ventes."
        ></Perk>
      </div>
      <div className="perk-row">
        <Perk
          subtitle="Mailing"
          title="Créez votre base de données clients"
          text="Constituez votre base de données avec les e-mails opt-in de vos clients et communiquez directement avec eux via une newsletter."
        ></Perk>
        <img src={db} alt="third perk"></img>
      </div>
      <div className="perk-row reversed">
        <img src={feedback} alt="fourth perk"></img>
        <Perk
          subtitle="Enquêtes clients"
          title="Cernez les besoins de vos clients"
          text="Récoltez des informations précieuses et spécifiques pour mieux cibler les attentes de vos clients et ainsi mettre en place des actions pertinentes. "
        ></Perk>
      </div>
    </section>
  );
};

export default Perks;
