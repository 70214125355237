import * as React from "react";
import "./Features.sass";
import Perk from "./Perk";
import { useState } from "react";

const Features = (props) => {
  const [feature, setFeature] = useState(1);
  return (
    <section
      id="features"
      className={props.color === "grape" ? "grape" : "white"}
    >
      <Perk
        subtitle={props.content ? props.content[0].subtext : ""}
        title={props.content ? props.content[0].title : ""}
        text={props.content ? props.content[0].text : ""}
      ></Perk>
      <div
        id="interactive-features"
        className={props.direction === "vertical" ? "vertical" : "horizontal"}
      >
        <img
          src={props.content ? props.content[1].image : ""}
          className={feature === 1 ? "show" : "hidden"}
          alt="first feature"
        ></img>
        <img
          src={props.content ? props.content[2].image : ""}
          className={feature === 2 ? "show" : "hidden"}
          alt="second feature"
        ></img>
        <img
          src={props.content ? props.content[3].image : ""}
          className={feature === 3 ? "show" : "hidden"}
          alt="third feature"
        ></img>
        <img
          src={props.content ? props.content[4].image : ""}
          className={feature === 4 ? "show" : "hidden"}
          alt="fourth feature"
        ></img>
        <ul>
          <li
            className={feature === 1 ? "active" : ""}
            onClick={() => setFeature(1)}
            onKeyDown={() => setFeature(1)}
          >
            <h3> {props.content ? props.content[1].title : ""}</h3>
            <p>{props.content ? props.content[1].text : ""}</p>
          </li>
          <li
            className={feature === 2 ? "active" : ""}
            onClick={() => setFeature(2)}
            onKeyDown={() => setFeature(2)}
          >
            <h3>{props.content ? props.content[2].title : ""}</h3>
            <p>{props.content ? props.content[2].text : ""}</p>
          </li>
          <li
            className={feature === 3 ? "active" : ""}
            onClick={() => setFeature(3)}
            onKeyDown={() => setFeature(3)}
          >
            <h3>{props.content ? props.content[3].title : ""}</h3>
            <p>{props.content ? props.content[3].text : ""}</p>
          </li>
          <li
            className={feature === 4 ? "active" : ""}
            onClick={() => setFeature(4)}
            onKeyDown={() => setFeature(4)}
          >
            <h3>{props.content ? props.content[4].title : ""}</h3>
            <p>{props.content ? props.content[4].text : ""}</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Features;
