import * as React from "react";
import "./Numbers.sass";

const Numbers = () => {
  return (
    <section id="numbers">
      <p>
        <b>Weely en chiffres</b>
      </p>

      <div id="numbers-holder">
        <div className="number">
          <h2>1000+ spins</h2>
          <p>
            La roue Weely a été tournée plus de 1000 fois depuis notre lancement
            en 2021.
          </p>
        </div>
        <div className="number">
          <h2>650 utilisateurs</h2>
          <p>Plus de 650 personnes ont joué dans nos commerces partenaires.</p>
        </div>
        <div className="number">
          <h2>50 prix</h2>
          <p>
            Gagnez jusqu'à 50 prix différents : pizzas, brunchs, réductions,
            cafés, et bien plus.
          </p>
        </div>
        <div className="number">
          <h2>250 spins</h2>
          <p>
            C'est le nombre de spins que l'on vous offre pour débuter avec
            Weely.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
