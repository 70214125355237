import * as React from "react";
import "./Steps.sass";
import Perk from "../components/Perk";
import step1 from "../images/step1.png";
import step2 from "../images/step2.png";
import step3 from "../images/step3.png";

const Steps = () => {
  return (
    <section id="steps" className="pattern-cross-dots-lg">
      <Perk
        subtitle="Installation"
        title="Une mise en place simple en 3 étapes"
        text="Weely est un outil qui peut être parametrer en toute autonomie depuis votre tableau de bord."
      ></Perk>
      <div id="step-holder">
        <div className="step">
          <div className="step-text">
            <h3>1. Créez votre compte</h3>
            <p>
              Choisissez votre abonnement, personnalisez votre roue et insérer
              vos prix.
            </p>
          </div>
          <img src={step1} alt="first step"></img>
        </div>
        <div className="step">
          <div className="step-text">
            <h3>2. Imprimez votre QR code</h3>
            <p>
              Imprimez votre QR code de façon autonome ou faites appel à nos
              services.
            </p>
          </div>
          <img src={step2} alt="second step"></img>
        </div>
        <div className="step">
          <div className="step-text">
            <h3>3. Faites tourner la roue !</h3>
            <p>
              L'aventure Weely peut commencer. Laissez vos clients faire le
              reste.
            </p>
          </div>
          <img src={step3} alt="third step"></img>
        </div>
      </div>
    </section>
  );
};

export default Steps;
