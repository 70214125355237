import * as React from "react";
import Layout from "../components/Layout";
import Numbers from "../components/Numbers";
import CTA from "../components/CTA";
import SolutionHero from "../components/SolutionHero";
import Steps from "../components/Steps";
import Features from "../components/Features";
import dashboard1 from "../images/admin1.png";
import dashboard2 from "../images/admin2.png";
import dashboard3 from "../images/admin3.png";
import dashboard4 from "../images/admin4.png";
import app1 from "../images/customize/colors.png";
import app2 from "../images/customize/actions.png";
import app3 from "../images/customize/questions.png";
import app4 from "../images/customize/prize.png";
import Perks from "../components/Perks";

import "./index.sass";

// markup
const Solution = () => {
  return (
    <Layout>
      <SolutionHero></SolutionHero>
      <Perks></Perks>
      <Steps></Steps>
      <Features
        color="grape"
        content={features1}
        direction="vertical"
      ></Features>
      <Features
        color="white"
        content={features2}
        direction="horizontal"
      ></Features>
      <Numbers></Numbers>
      <CTA></CTA>
    </Layout>
  );
};

const features1 = [
  {
    subtext: "Personnalisation",
    title: "Créez une expérience à la hauteur de votre marque",
    text:
      "Grâce à votre tableau de bord Weely, vous avez accès à des analyses poussées et un contrôle sur toute votre stratégie.",
  },
  {
    title: "Choix des couleurs",
    text:
      "Reprenez les couleurs de votre entreprise de façon simple et ludique.",
    image: app1,
  },
  {
    title: "Choix des actions",
    text:
      "Sélectionnez les actions que vous souhaitez intégrer au jeu (Instagram, Facebook, Google).",
    image: app2,
  },
  {
    title: "Choix des questions",
    text:
      "Choisissez parmi deux formats de question pour lancer votre enquête client.",
    image: app3,
  },
  {
    title: "Choix des prix",
    text:
      "Paramétrez vos prix ainsi que le pourcentage de gain directement depuis votre interface.",
    image: app4,
  },
];

const features2 = [
  {
    subtext: "Tableau de bord",
    title: "Accédez à tous vos résultats en 2 clics.",
    text:
      "Grâce à votre interface privée Weely, vous pourrez accéder à des analyses poussées et une vision à 360° de vos résultats.",
  },
  {
    title: "Votre revenu généré",
    text:
      "Mesurez en temps réel le chiffre d’affaires généré par votre programme de fidélité. Identifiez les prix qui fonctionnent le mieux et améliorez constamment votre rentabilité.",
    image: dashboard1,
  },
  {
    title: "Vos chiffres réseaux sociaux",
    text:
      "Accédez en temps réel au nombre de nouveaux followers apportés par Weely sur vos réseaux sociaux (Instagram, Facebook, Google Business).",
    image: dashboard2,
  },
  {
    title: "Vos enquêtes clients",
    text:
      "Consultez les résultats de vos enquêtes clients pour affiner et mieux répondre à leurs attentes.",
    image: dashboard3,
  },
  {
    title: "Votre base de données client",
    text:
      "Consultez votre base de données client opt-in. Exportez-là au format Excel/CSV et communiquez par newsletter avec votre communauté.",
    image: dashboard4,
  },
];

export default Solution;
