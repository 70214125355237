import * as React from "react";
import "./SolutionHero.sass";
import hero from "../images/solution_hero.png";

// markup
const SolutionHero = () => {
  return (
    <section id="solution-hero">
      <h1>Découvrez la plateforme de fidélisation 2.0</h1>
      <h4>
        Weely offre une solution 4 en 1 pour interagir et dynamiser votre
        communauté. Tout cela en proposant une expérience unique.
      </h4>
      <img src={hero} alt="our applications"></img>
    </section>
  );
};

export default SolutionHero;
