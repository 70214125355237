import * as React from "react";
import "./Perk.sass";

const Perk = (props) => {
  return (
    <div className="perk-holder">
      <b>{props.subtitle}</b>
      <h2>{props.title}</h2>
      <p>{props.text}</p>
    </div>
  );
};

export default Perk;
